import logo from "./logo.svg";
import "./App.css";
import { Header } from "./components/Header";
import { router } from "./Router";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <>
      <Header />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
