import { createBrowserRouter } from "react-router-dom";
import { FormV2 } from "./components/Formv2";
import Price from "./components/Price";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <FormV2 />,
  },
  {
    path: "/prix",
    element: <Price />,
  },
]);
