import {
  Box,
  Button,
  // FormControl,
  FormControlLabel,
  Grid,
  // InputLabel,
  // List,
  // ListItemText,
  // MenuItem,
  Modal,
  // Select,
  // SvgIcon,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import sparkles from "../images/Sparkles.png";
import broom from "../images/Broom.png";
import necktie from "../images/Necktie.png";
import lightBulb from "../images/Light Bulb.png";
// import HelpIcon from "@mui/icons-material/Help";

export const FormV2 = () => {
  const [service, setService] = useState(""); //ménage ou repassage
  const [produits, setProduits] = useState(false);
  const [animaux, setAnimaux] = useState(false);
  const [horaire, setHoraire] = useState(""); // simple ou supplement
  const [erreurs, setErreurs] = useState([]);
  const [heures, setHeures] = useState("");
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [confidentialite, setConfidentialite] = useState(false);
  const [utilisation, setUtilisation] = useState(false);
  // const [creditImpot, setCreditImpot] = useState(false);
  const [infos, setInfos] = useState(false);

  useEffect(() => {
    console.log(process.env.REACT_APP_PRICE);
    const price = parseFloat(process.env.REACT_APP_PRICE).toFixed(2);
    const priceSupplement = parseFloat(price) + 5;
    if (horaire === "simple") {
      if (produits) {
        setTotalPrice(price * heures + heures * 5);
      } else {
        setTotalPrice(price * heures);
      }
    }
    if (horaire === "supplement") {
      if (produits) {
        setTotalPrice(priceSupplement * heures + heures * 5);
      } else {
        setTotalPrice(priceSupplement * heures);
      }
    }
  }, [service, horaire, heures, produits]);

  const handleRedirection = () => {
    setErreurs([]);
    if (service === "" || horaire == "") {
      if (service === "") {
        setErreurs((state) => [...state, "Aucun service sélectionné"]);
      }
      if (horaire === "") {
        setErreurs((state) => [
          ...state,
          "Aucune tranche horaire sélectionnée",
        ]);
      }
    } else {
      redirect();
    }
  };

  const redirect = () => {
    // if (!creditImpot) {
    if (horaire === "simple" && !produits) {
      if (animaux) {
        window.location.href = `https://rdv.cest-net.fr?service=2&duree=${
          heures * 60
        }&a=1&p=${service}`;
      } else {
        window.location.href = `https://rdv.cest-net.fr?service=2&duree=${
          heures * 60
        }&p=${service}`;
      }
    }
    if (horaire === "supplement" && !produits) {
      if (animaux) {
        window.location.href = `https://rdv.cest-net.fr?service=1&duree=${
          heures * 60
        }&a=1&p=${service}`;
      } else {
        window.location.href = `https://rdv.cest-net.fr?service=1&duree=${
          heures * 60
        }&p=${service}`;
      }
    }
    if (horaire === "simple" && produits) {
      if (animaux) {
        window.location.href = `https://rdv.cest-net.fr?service=4&duree=${
          heures * 60
        }&a=1&p=${service}`;
      } else {
        window.location.href = `https://rdv.cest-net.fr?service=4&duree=${
          heures * 60
        }&p=${service}`;
      }
    }
    if (horaire === "supplement" && produits) {
      if (animaux) {
        window.location.href = `https://rdv.cest-net.fr?service=3&duree=${
          heures * 60
        }&a=1&p=${service}`;
      } else {
        window.location.href = `https://rdv.cest-net.fr?service=3&duree=${
          heures * 60
        }&p=${service}`;
      }
    }
    // } else {
    //   if (horaire === "simple" && !produits) {
    //     if (animaux) {
    //       window.location.href = `https://rdv.cest-net.fr?service=7&duree=${
    //         heures * 60
    //       }&a=1&p=${service}`;
    //     } else {
    //       window.location.href = `https://rdv.cest-net.fr?service=7&duree=${
    //         heures * 60
    //       }&p=${service}`;
    //     }
    //   }
    //   if (horaire === "supplement" && !produits) {
    //     if (animaux) {
    //       window.location.href = `https://rdv.cest-net.fr?service=6&duree=${
    //         heures * 60
    //       }&a=1&p=${service}`;
    //     } else {
    //       window.location.href = `https://rdv.cest-net.fr?service=6&duree=${
    //         heures * 60
    //       }&p=${service}`;
    //     }
    //   }
    //   if (horaire === "simple" && produits) {
    //     if (animaux) {
    //       window.location.href = `https://rdv.cest-net.fr?service=8&duree=${
    //         heures * 60
    //       }&a=1&p=${service}`;
    //     } else {
    //       window.location.href = `https://rdv.cest-net.fr?service=8&duree=${
    //         heures * 60
    //       }&p=${service}`;
    //     }
    //   }
    //   if (horaire === "supplement" && produits) {
    //     if (animaux) {
    //       window.location.href = `https://rdv.cest-net.fr?service=5&duree=${
    //         heures * 60
    //       }&a=1&p=${service}`;
    //     } else {
    //       window.location.href = `https://rdv.cest-net.fr?service=5&duree=${
    //         heures * 60
    //       }&p=${service}`;
    //     }
    //   }
    // }
  };

  return (
    <>
      <Box sx={{ mx: "auto", m: 1 }}>
        <Box
          sx={{
            backgroundColor: "white",
            maxWidth: "820px",
            alignSelf: "center",
            my: 5,
            p: 4,
            mx: "auto",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px",
          }}
        >
          <Typography sx={{ fontWeight: "900", fontSize: "24px", mb: 2 }}>
            Quel service souhaitez-vous ?
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                onClick={() =>
                  service === "ménage" ? setService("") : setService("ménage")
                }
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
                    p: 2,
                    borderRadius: "20px",
                    backgroundColor:
                      service === "ménage" ? "colors.lightBlue" : "white",
                    border: service === "ménage" ? "3px solid #0A7AFF" : "0",
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <img src={broom} alt="icone ménage" />
                  <Typography
                    sx={{ fontWeight: "700", fontSize: "18px", ml: 1 }}
                  >
                    Ménage
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                onClick={() =>
                  service === "repassage"
                    ? setService("")
                    : setService("repassage")
                }
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
                    p: 2,
                    borderRadius: "20px",
                    backgroundColor:
                      service === "repassage" ? "colors.lightBlue" : "white",
                    border: service === "repassage" ? "3px solid #0A7AFF" : "0",
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <img src={necktie} alt="icone repassage" />
                  <Typography
                    sx={{ fontWeight: "700", fontSize: "18px", ml: 1 }}
                  >
                    Repassage
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center" mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={produits}
                    onChange={() => setProduits(!produits)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Produits ménagers fournis par C'EST NET"
              />
              <Typography sx={{ color: "colors.blue", fontWeight: "700" }}>
                (+5€ par heure)
              </Typography>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={animaux}
                  onChange={() => setAnimaux(!animaux)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="J'ai des animaux de compagnie"
            />

            <Box
              mt={3}
              p={2}
              sx={{
                backgroundColor: "colors.lightBlue",
                border: "1px solid #E5EDFF",
                borderRadius: "20px",
              }}
            >
              <Box display="flex" alignItems="center">
                <img src={lightBulb} alt="ampoule" />
                <Typography sx={{ fontWeight: "800", fontSize: "20px" }}>
                  Merci de mettre à disposition de votre pro :
                </Typography>
              </Box>

              <Typography mt={1}>
                aspirateur, seau, serpillère et raclette, ainsi que produits
                ménagers (SdB, WC, sol), éponges, chiffons et gants si vous ne
                prenez pas l'option produits ménagers fournis par C'EST NET.
              </Typography>
            </Box>

            <Typography
              sx={{ fontWeight: "900", fontSize: "24px", mb: 2, mt: 5 }}
            >
              Choisissez votre tranche horaire
            </Typography>

            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                onClick={() =>
                  horaire === "simple" ? setHoraire("") : setHoraire("simple")
                }
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      horaire === "simple" ? "colors.lightBlue" : "white",
                    border: horaire === "simple" ? "3px solid #0A7AFF" : "0",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                  minHeight="81px"
                >
                  <Typography sx={{ fontWeight: "900", fontSize: "20px" }}>
                    Entre 9h et 20h
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={5}
                onClick={() =>
                  horaire === "supplement"
                    ? setHoraire("")
                    : setHoraire("supplement")
                }
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      horaire === "supplement" ? "colors.lightBlue" : "white",
                    border:
                      horaire === "supplement" ? "3px solid #0A7AFF" : "0",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography
                    sx={{
                      fontWeight: "900",
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    Avant 9h, après 20h, dimanche ou jour férié
                  </Typography>
                  <Typography
                    sx={{
                      color: "colors.blue",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    +5€ par heure
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Typography
              sx={{ display: horaire === "simple" ? "block" : "none" }}
            >
              * Prestation finissant après 9h ou commençant avant 20h
            </Typography>
            <Typography
              sx={{ display: horaire === "supplement" ? "block" : "none" }}
            >
              * Prestation finissant avant 9h, commençant après 20h, ayant lieu
              un dimanche ou un jour férié
            </Typography>

            <Typography sx={{ fontWeight: "900", fontSize: "24px", my: 2 }}>
              Durée de la prestation :
            </Typography>
            <Grid container spacing={1}>
              <Grid
                item
                onClick={() => (heures === 2 ? setHeures("") : setHeures(2))}
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 2 ? "colors.lightBlue" : "white",
                    border: heures === 2 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>2h</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() =>
                  heures === 2.5 ? setHeures("") : setHeures(2.5)
                }
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 2.5 ? "colors.lightBlue" : "white",
                    border: heures === 2.5 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>2h30</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() => (heures === 3 ? setHeures("") : setHeures(3))}
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 3 ? "colors.lightBlue" : "white",
                    border: heures === 3 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>3h</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() =>
                  heures === 3.5 ? setHeures("") : setHeures(3.5)
                }
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 3.5 ? "colors.lightBlue" : "white",
                    border: heures === 3.5 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>3h30</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() => (heures === 4 ? setHeures("") : setHeures(4))}
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 4 ? "colors.lightBlue" : "white",
                    border: heures === 4 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>4h</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() => (heures === 5 ? setHeures("") : setHeures(5))}
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 5 ? "colors.lightBlue" : "white",
                    border: heures === 5 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>5h</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() => (heures === 6 ? setHeures("") : setHeures(6))}
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 6 ? "colors.lightBlue" : "white",
                    border: heures === 6 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>6h</Typography>
                </Box>
              </Grid>
              <Grid
                item
                onClick={() => (heures === 7 ? setHeures("") : setHeures(7))}
                className="cursor"
              >
                <Box
                  sx={{
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    backgroundColor:
                      heures === 7 ? "colors.lightBlue" : "white",
                    border: heures === 7 ? "3px solid #0A7AFF" : "0",
                    minWidth: "40px",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p={3}
                >
                  <Typography>7h</Typography>
                </Box>
              </Grid>
            </Grid>

            {/* <Typography sx={{ fontWeight: "900", fontSize: "24px", my: 2 }}>
              Avance immédiate du crédit d'impôt :
            </Typography>
            <Box display="flex">
              <Typography
                sx={{ color: "colors.blue", fontWeight: "700", mb: 2, mr: 1 }}
              >
                Avec cette option, vous payerez seulement 50% le lendemain de la
                prestation, si vous êtes éligible{" "}
              </Typography>
              <HelpIcon
                sx={{ color: "colors.blue" }}
                className="cursor"
                onClick={() => setInfos(true)}
              />
            </Box>

            <FormControlLabel
              control={
                <Switch
                  checked={creditImpot}
                  onChange={() => setCreditImpot(!creditImpot)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <Typography sx={{ fontWeight: "700" }}>
                  Je souhaite une avance immédiate du crédit d'impôt
                </Typography>
              }
            /> */}
            <Typography
              sx={{
                backgroundColor: "colors.lightGreen",
                borderRadius: "20px",
                p: 2,
                fontWeight: "400",
                fontStyle: "italic",
                fontSize: "14px",
                my: 2,
                textAlign: "justify",
              }}
            >
              <Box component="span" sx={{ fontWeight: "700" }}>
                Imposable ou non,{" "}
              </Box>
              bénéficiez d'une{" "}
              <Box component="span" sx={{ fontWeight: "700" }}>
                remise de 50%{" "}
              </Box>
              sur le prix facturé de l'ensemble de nos prestations grâce à
              l'avance immédiate du crédit d'impôt. Pour en savoir plus sur le
              crédit d'impôt,{" "}
              <Box component="span" sx={{ fontWeight: "700" }}>
                <a
                  href="https://www.cest-net.fr/vos-avantages-fiscaux"
                  target="_blank"
                  className="infos"
                  rel="noreferrer"
                >
                  cliquez ici !
                </a>
              </Box>
            </Typography>

            {/* {!creditImpot ? ( 
              <>*/}
            <Box display="flex" justifyContent="space-between" mt={5}>
              <Typography sx={{ fontWeight: "800", fontSize: "24px" }}>
                Prix par prestation
              </Typography>
              <Typography sx={{ fontWeight: "800", fontSize: "24px" }}>
                {totalPrice.toFixed(2)}€
              </Typography>
            </Box>
            {/* <Box display="flex" justifyContent="space-between" mt={1}>
                  <Typography sx={{ color: "colors.green", fontWeight: "500" }}>
                    Crédit d'impôt
                  </Typography>
                  <Typography sx={{ color: "colors.green", fontWeight: "500" }}>
                    -{(totalPrice / 2).toFixed(2)}€
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Typography sx={{ fontWeight: "500" }}>
                    Montant après crédit d'impôt
                  </Typography>
                  <Typography sx={{ fontWeight: "500" }}>
                    {(totalPrice / 2).toFixed(2)}€
                  </Typography>
                </Box>
              </>
            ) : (
              <Box display="flex" justifyContent="space-between" mt={5}>
                <Typography sx={{ fontWeight: "800", fontSize: "24px" }}>
                  Prix par prestation
                </Typography>
                <Typography sx={{ fontWeight: "800", fontSize: "24px" }}>
                  {(totalPrice / 2).toFixed(2)}€
                </Typography>
              </Box>
            )} */}

            <Box mt={5}>
              <Box display="flex" flexDirection="column" alignItems="center">
                {erreurs.map((e) => {
                  console.log(e);
                  return (
                    <Typography key={e} sx={{ fontSize: "14px", color: "red" }}>
                      {e}
                    </Typography>
                  );
                })}
              </Box>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "colors.blue",
                  borderRadius: "100px",
                  textTransform: "none",
                  fontSize: "20px",
                  fontWeight: "500",
                  py: 2,
                }}
                onClick={() => handleRedirection()}
              >
                Prendre rendez-vous
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "center" }}
        justifyContent={{ md: "space-evenly" }}
        sx={{
          boxShadow: "0px -4px 14px rgba(0, 0, 0, 0.15)",
          backgroundColor: "white",
          py: 2,
        }}
      >
        <Typography className="link" onClick={() => setConfidentialite(true)}>
          Politique de confidentialité
        </Typography>
        <Typography className="link" onClick={() => setUtilisation(true)}>
          Conditions d'utilisation
        </Typography>
      </Box>

      <Modal open={confidentialite} onClose={() => setConfidentialite(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            width: { xs: "100%", sm: "90%", md: "700px" },
            transform: "translate(-50%, -50%)",
          }}
          overflow="auto"
          height={{ xs: "100%", sm: "80%" }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              p: 4,
              mx: "auto",
              borderRadius: "20px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "20px",
                mb: 2,
              }}
            >
              Politique de confidentialité
            </Typography>
            <Typography>
              Le Client est informé des réglementations concernant la
              communication marketing, la loi du 21 Juin 2014 pour la confiance
              dans l'Économie Numérique, la Loi Informatique et Liberté du 06
              Août 2004 ainsi que du Règlement Général sur la Protection des
              Données (RGPD : n° 2016-679).
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              1. Responsables de la collecte des données personnelles
            </Typography>
            <Typography>
              Pour les Données Personnelles collectées dans le cadre de la
              création du compte personnel de l’Utilisateur et de sa navigation
              sur le Site, le responsable du traitement des Données Personnelles
              est : Bruno Arnaudo.{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              est représenté par C'EST NET, son représentant légal.
            </Typography>
            <Typography mt={1}>
              En tant que responsable du traitement des données qu’il collecte,{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              s’engage à respecter le cadre des dispositions légales en vigueur.
              Il lui appartient notamment au Client d’établir les finalités de
              ses traitements de données, de fournir à ses prospects et clients,
              à partir de la collecte de leurs consentements, une information
              complète sur le traitement de leurs données personnelles et de
              maintenir un registre des traitements conforme à la réalité.
              Chaque fois que{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              traite des Données Personnelles,{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              prend toutes les mesures raisonnables pour s’assurer de
              l’exactitude et de la pertinence des Données Personnelles au
              regard des finalités pour lesquelles{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              les traite.
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              2. Finalité des données collectées
            </Typography>
            <Typography>
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              est susceptible de traiter tout ou partie des données pour :
            </Typography>
            <ul>
              <li>
                permettre la navigation sur le Site et la connexion à l'espace
                privée par l’utilisateur : données de connexion et d’utilisation
                du Site pour prévenir et lutter contre la fraude informatique
                (spamming, hacking…) : matériel informatique utilisé pour la
                navigation, l’adresse IP, pages visitées ;
              </li>
              <li>
                pour améliorer la navigation sur le Site : données de connexion
                et d’utilisation.
              </li>
            </ul>
            <Typography mt={1}>
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              ne commercialise pas vos données personnelles qui sont donc
              uniquement utilisées par nécessité ou à des fins statistiques et
              d’analyses.
            </Typography>

            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "colors.blue",
                  borderRadius: "100px",
                  textTransform: "none",
                  fontSize: "20px",
                  fontWeight: "500",
                  py: 2,
                  px: 7,
                }}
                onClick={() => setConfidentialite(false)}
              >
                Fermer
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={utilisation} onClose={() => setUtilisation(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: { xs: "100%", sm: "90%", md: "700px" },
            transform: "translate(-50%, -50%)",
          }}
          overflow="auto"
          height={{ xs: "100%", sm: "80%" }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              p: 4,
              mx: "auto",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "20px",
                mb: 2,
              }}
            >
              Conditions d'utilisation
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              1. Présentation et principe
            </Typography>
            <Typography>
              Est désigné ci-après : Utilisateur, tout internaute se connectant
              et utilisant le site susnommé :{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>
            </Typography>
            <Typography mt={1}>
              C'EST NET s’efforce de fournir sur le site{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              les informations les plus précises et à jour possibles (sous
              réserve de modifications apportées depuis leur mise en ligne),
              mais ne saurait garantir l'exactitude, la complétude et
              l'actualité des informations diffusées sur son site, qu’elles
              soient de son fait ou du fait des tiers partenaires qui lui
              fournissent ces informations. En conséquence, l'internaute devra
              donc s'assurer de l'exactitude des informations et signaler toutes
              modifications du site qu'il jugerait utile. C'EST NET n'est en
              aucun cas responsable de l'utilisation faite de ces informations,
              et de tout préjudice direct ou indirect pouvant en découler.
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              2. Accessibilité
            </Typography>
            <Typography>
              Le site{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              est par principe accessible aux utilisateurs 24/24h, 7/7j, sauf
              interruption, programmée ou non, pour les besoins de sa
              maintenance ou en cas de force majeure. En cas d’impossibilité
              d’accès au service, C'EST NET s’engage à faire son maximum afin de
              rétablir l’accès au service. N’étant soumis qu’à une obligation de
              moyen, C'EST NET ne saurait être tenu pour responsable de tout
              dommage, quelle qu’en soit la nature, résultant d’une
              indisponibilité du service.
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              3. Propriété intellectuelle
            </Typography>
            <Typography>
              C'EST NET est propriétaire exclusif de tous les droits de
              propriété intellectuelle ou détient les droits d’usage sur tous
              les éléments accessibles sur le site, tant sur la structure que
              sur les textes, images, graphismes, logo, icônes, sons, logiciels…
            </Typography>
            <Typography mt={1}>
              Toute reproduction totale ou partielle du site{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>
              , représentation, modification, publication, adaptation totale ou
              partielle de l'un quelconque de ces éléments est interdite, sauf
              autorisation écrite préalable de C'EST NET. À défaut, elle sera
              considérée comme constitutive d’une contrefaçon et passible de
              poursuite conformément aux dispositions des articles L.335-2 et
              suivants du Code de Propriété Intellectuelle.
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              4. Liens hypertextes et cookies
            </Typography>
            <Typography>4.1 Liens hypertextes</Typography>
            <Typography mt={1}>
              Le site{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>{" "}
              peut contenir un certain nombre de liens hypertextes vers d’autres
              sites. C'EST NET ne dispose d'aucun moyen pour contrôler les sites
              en connexion avec son site internet et ne répond pas de la
              disponibilité de tels sites et sources externes, ni ne la
              garantit. C'EST NET ne peut être tenue pour responsable de tout
              dommage, de quelque nature que ce soit, résultant du contenu de
              ces sites ou sources externes, et notamment des informations,
              produits ou services qu’ils proposent.
            </Typography>
            <Typography mt={1}>
              Si vous naviguez vers un de ces sites, veuillez noter qu’ils
              possèdent leur propre politique de confidentialité et que notre
              responsabilité s’arrête au moment où vous quittez notre site.
            </Typography>
            <Typography mt={1}>
              Les propriétaires de sites internet tiers ne peuvent mettre en
              place un hyperlien en direction de ce site sans l'autorisation
              expresse et préalable de C'EST NET qui se réserve le droit
              d’accepter ou de refuser un hyperlien sans avoir à en justifier sa
              décision.
            </Typography>
            <Typography mt={1}>4.2 Cookies</Typography>
            <Typography mt={1}>
              L’utilisateur est informé que lors de ses visites sur le site{" "}
              <a
                href="https://www.cest-net.fr"
                target="_blank"
                rel="noreferrer"
              >
                www.cest-net.fr
              </a>
              , un ou des cookies sont susceptibles de s’installer
              automatiquement sur son ordinateur par l'intermédiaire de son
              logiciel de navigation. Un cookie est un bloc de données qui ne
              permet pas d'identifier l'utilisateur, mais qui enregistre des
              informations relatives à la navigation de celui-ci sur le site. Il
              contient plusieurs données qui sont stockées sur votre ordinateur
              dans un simple fichier texte auquel un serveur accède pour lire et
              enregistrer des informations.
            </Typography>
            <Typography mt={1}>
              Le paramétrage du logiciel de navigation permet d’informer de la
              présence de cookie et éventuellement, de la refuser de la manière
              décrite à l’adresse suivante :{" "}
              <a href="https://www.cnil.fr" target="_blank" rel="noreferrer">
                www.cnil.fr
              </a>
              .
            </Typography>
            <Typography mt={1}>4.3 Les cookies tiers</Typography>
            <Typography mt={1}>
              Notre site peut contenir des liens vers ou depuis des sites
              partenaires et d’autres sites tiers. Si vous naviguez vers un de
              ces sites, veuillez noter qu’ils possèdent leur propre politique
              de confidentialité et que notre responsabilité s’arrête au moment
              où vous quittez notre site. Vérifiez les politiques de
              confidentialité avant de transmettre vos données personnelles à
              des sites tiers.
            </Typography>

            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "colors.blue",
                  borderRadius: "100px",
                  textTransform: "none",
                  fontSize: "20px",
                  fontWeight: "500",
                  py: 2,
                  px: 7,
                }}
                onClick={() => setUtilisation(false)}
              >
                Fermer
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal open={infos} onClose={() => setInfos(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: { xs: "100%", sm: "90%", md: "700px" },
            transform: "translate(-50%, -50%)",
          }}
          overflow="auto"
          height={{ xs: "100%", sm: "auto" }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              p: 4,
              mx: "auto",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "20px",
            }}
            height="100%"
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "20px",
                mb: 2,
              }}
            >
              Avance immédiate de crédit d'impôt
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              Eligibilité
            </Typography>
            <Typography>
              Vous devez être résident fiscal en France et avoir déjà fait une
              déclaration de revenus.
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              Est-ce obligatoire ?
            </Typography>
            <Typography>
              Non, si vous n'activez pas cette option vous pourrez toujours
              bénéficier du crédit d'impôt classique (fin d'année).
            </Typography>
            <Typography sx={{ fontWeight: "700", my: 1.5 }}>
              Comment se passe le paiement ?
            </Typography>
            <Typography>
              Le lendemain de chaque session l'URSSAF vous prélèvera de 50% du
              montant total (sous réserve d'éligibilité aux avantages fiscaux)
              et paiera C'EST NET.
            </Typography>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "colors.blue",
                  borderRadius: "100px",
                  textTransform: "none",
                  fontSize: "20px",
                  fontWeight: "500",
                  py: 2,
                  px: 7,
                }}
                onClick={() => setInfos(false)}
              >
                Fermer
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
