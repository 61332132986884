const theme = {
    palette:{
        colors: {
            lightGreen:'#D9FFE2',
            black: '#000000',
            lightBlue: '#F2F8FF',
            blue:'#0A7AFF', 
            green: '#35C658'
        }
    },
    typography: {
        fontFamily: [
            'Urbanist',
        ].join(','),
    },
     
}

export default theme