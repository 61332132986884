import { Box } from '@mui/material'
import React from 'react'
import logo from '../images/Logo-CEST-NET 1.png'

export const Header = () => {
  return (
    <Box sx={{boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)', backgroundColor:'white'}}>
      <Box sx={{textAlign:{xs:'center', md:'left'}}}>
        <img src={logo} alt="C'est Net" width={250}/>
      </Box>
      
    </Box>
  )
}
