import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";

const Price = () => {
  const [connected, setConnected] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const pass = useRef(null);

  const checkPass = () => {
    if (pass.current.value === process.env.REACT_APP_PASSWORD) {
      setConnected(true);
    }
  };

  const changePrice = async () => {
    setLoading(true);
    const price = parseFloat(input);
    const getServices = await fetch(
      "https://hook.eu1.make.com/8i78n1g54fvjon9f5f32h3zk6ld8wfpq"
    );
    const res = await getServices.json();
    // const res = [
    //   {
    //     id: 71,
    //     name: "test api",
    //     duration: 60,
    //     price: 22,
    //     currency: "EUR",
    //     description: "",
    //     location: "",
    //     availabilitiesType: "flexible",
    //     attendantsNumber: 1,
    //     categoryId: 2,
    //   },
    // ];

    const changedPrice = res.map((service) => {
      if (service.name.includes("horaires spéciaux")) {
        if (
          service.name.includes("produits") &&
          service.name.includes("crédit d'impôt")
        ) {
          service.price = (
            (service.duration * ((price + 2 + 5) / 60)) /
            2
          ).toFixed(2);
        } else if (service.name.includes("produits")) {
          service.price = (service.duration * ((price + 5 + 5) / 60)).toFixed(
            2
          );
        } else if (service.name.includes("crédit d'impôt")) {
          service.price = ((service.duration * ((price + 5) / 60)) / 2).toFixed(
            2
          );
        } else {
          service.price = (service.duration * ((price + 5) / 60)).toFixed(2);
        }
      } else {
        if (
          service.name.includes("produits") &&
          service.name.includes("crédit d'impôt")
        ) {
          service.price = ((service.duration * ((price + 5) / 60)) / 2).toFixed(
            2
          );
        } else if (service.name.includes("produits")) {
          service.price = (service.duration * ((price + 5) / 60)).toFixed(2);
        } else if (service.name.includes("crédit d'impôt")) {
          service.price = ((service.duration * (price / 60)) / 2).toFixed(2);
        } else {
          service.price = (service.duration * (price / 60)).toFixed(2);
        }
      }
      return service;
    });
    console.log(changedPrice);

    fetch("https://hook.eu1.make.com/qypth3nwtzb5qyn1ab35qxqkg93w0yts", {
      method: "POST",
      body: JSON.stringify({ services: changedPrice, price: price }),
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 500) {
          setError("Une erreur est survenue");
        } else {
          setSuccess("Les prix ont bien été changé");
        }
      })
      .catch(() => {
        setError("Une erreur est survenue");
        setSuccess(false);
        setLoading(false);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
    setError(false);
  };

  return (
    <>
      {connected ? (
        <>
          <Stack width="50%" sx={{ margin: "auto", mt: 4 }} spacing={2}>
            <TextField
              value={input}
              onChange={(e) => setInput(e.target.value)}
              mb={2}
            />
            <Button variant="contained" onClick={changePrice}>
              Changer le prix
            </Button>
          </Stack>
          <Snackbar
            open={!!success}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="success" elevation={6} variant="filled">
              {success}
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!error}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert severity="error" elevation={6} variant="filled">
              {error}
            </Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        <>
          <Stack spacing={2} width="50%" sx={{ margin: "auto", mt: 4 }}>
            <TextField inputRef={pass} />
            <Button variant="contained" onClick={checkPass}>
              se connecter
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};

export default Price;
